import React from 'react';
import { graphql, PageProps } from 'gatsby';
import { PartnersIndexPageQuery } from '../types/graphql-types';
import Ctx from '../types/page-context';
import Box from '@pagerland/common/src/components/Box';
import Container from '@pagerland/common/src/components/Container';
import {MDXRenderer} from 'gatsby-plugin-mdx';
import ThemeMdx from "../containers/ThemeMdx";

const WrapperProps = {
  pt: {
    _: 56,
    md: 64,
    lg: 192,
  },
  pb: {
    _: 56,
    md: 64,
    lg: 96,
  },
};
const ContainerProps = {
  textAlign: 'left'
};

type Props = PageProps<PartnersIndexPageQuery, Ctx>;
const PartnersIndexTemplate: React.FC<Props> = ({data, pageContext, location}) => {
  const page = (
    <ThemeMdx title={data.info.frontmatter.title} data={data} location={location.pathname} pageContext={pageContext} >
      <Box {...WrapperProps}>
        <Container {...ContainerProps}>
          <MDXRenderer frontmatter={data.info.frontmatter}>{data.info.body}</MDXRenderer>
        </Container>
      </Box>
    </ThemeMdx>
  );

  return page;
};

export default PartnersIndexTemplate;
export const pageQuery = graphql`
  query PartnersIndexPage($id: String!, $idFrontmatter: String!, $lang: String!) {
    info: mdx(id: { eq: $id }) {
      ...PartnersIndexPageFragment
    }
    otherLanguages: allMdx(filter: {frontmatter: {id: {eq: $idFrontmatter}, lang: {ne: $lang}}}) {
      edges {
        node {
          frontmatter {
            lang
            slug
          }
        }
      }
    }
  }
`;

export const partnersIndexPageFragment = graphql`
  fragment PartnersIndexPageFragment on Mdx {
    frontmatter {
      slug
      title
      lang
    }
    body
  }
`;
